<template>
	<div id="state-login">

		<form class="phi-card" @submit.prevent="login()">

			<div class="language-picker">
				<select @input="$store.dispatch('setLanguage', $event.target.value)">
					<option v-for="(languageName, languageCode) in $store.state.i18n.available"
						:key="languageCode"
						:value="languageCode"
						:selected="$store.getters.isCurrentLanguage(languageCode)"
					>{{ languageName }}</option>
				</select>
			</div>

			<img :src="settings.logo" :alt="settings.title">

			<div class="phi-card-contents">
				<phi-input @input="error = null" v-model="username" :label="$t('StateLogin.username')" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"></phi-input>
				<phi-input @input="error = null" v-model="password" :label="$t('StateLogin.password')" type="password"></phi-input>
			</div>

			<div class="phi-card-actions">
				<button class="phi-button phi-main login" :class="{danger: !!error}">
					<span v-show="isLoading">{{ $t('StateLogin.loggingin') }}</span>
					<span v-show="!isLoading && !error">{{ $t('StateLogin.login') }}</span>
					<span v-show="!isLoading && error" v-text="error"></span>
				</button>
				<button class="phi-button google" v-if="googleLoginButton" type="button" @click="googleLogin()">{{ $t('StateLogin.google') }}</button>
				<button class="phi-button office365" type="button" v-if="office365LoginButton" @click="office365Login()">{{ $t('StateLogin.office365') }}</button>
				<button class="phi-button apple" type="button" v-if="appleLoginButton" @click="appleLogin()">{{ $t('StateLogin.apple') }}</button>
			</div>

			<p class="disclaimer" v-if="settings.disclaimerURL">
				<span>{{ $t('StateLogin.disclaimer') }}</span>
				<a href="#" @click.prevent="window.open(settings.disclaimerURL, '_system')"> {{ $t('here') }} </a>
			</p>
		</form>
		<button v-if="!$store.state.isFixed" class="reset" @click="resetCode()">{{ $t('StateLogin.changecode') }}</button>

	</div>
</template>

<script>
import app from '../store/app.js';

export default {
	data() {
		return {
			app,
			window,
			username: null,
			password: null,
			error: null,
			isLoading: false
		}
	},

	computed: {
		settings() {
			return this.$store.state.settings ? this.$store.state.settings : {};
		},

		googleLoginButton() {
			return this.settings.googleLogin? false : true;
		},

		office365LoginButton() {
			
			return this.settings.office365Login? false : true;
		},

		appleLoginButton() {

			let deviceType = (navigator.userAgent.match(/iPad/i))  == "iPad" ? "iPad" :
                (navigator.userAgent.match(/iPhone/i))  == "iPhone" ? "iPhone" :
                (navigator.userAgent.match(/Android/i)) == "Android" ? "Android" :
                (navigator.userAgent.match(/BlackBerry/i)) == "BlackBerry" ? "BlackBerry" : "null";

			if (deviceType == "iPad" || deviceType == "iPhone") {
				return false;
			}

			return false;
		}
	},

	methods: {
		login() {
			this.error = null;

			if (!this.username || !this.password) {
				this.error = "debes escribir tu usuario y contraseña";
				return;
			}

			if (this.username == "demo") {
				this.password = this.username;
				this.$store.dispatch("setUrl", "https://demo.api.phidias.co");
			}

			this.isLoading = true;
			this.$store.dispatch("authenticate", {method: "password", username: this.username, password: this.password})
				.then(() => this.redirect())
				.catch(error => {
					this.error = "usuario o contraseña incorrectos";
					this.isLoading = false;
				});
		},

		googleLogin() {

			let pwebClientId = this.$store.getters.getSetting("phidias.oauth.google.webClientId");					

			this.$store.dispatch("authenticate", {method:"google", webClientId:pwebClientId})
				.then(() => this.redirect())
				.catch(error => {
					this.error = "usuario de Google no válido";
				});
		},

		office365Login() {
			this.$store.dispatch("authenticate", {method:"office365"})
				.then(() => this.redirect())
				.catch(error => {
					this.error = "usuario de Office no válido";
				});
		},

		appleLogin() {
			this.$store.dispatch("authenticate", {method:"apple"})
				.then(() => this.redirect())
				.catch(error => {
					this.error = "usuario de Apple no válido";
				});
		},

		redirect() {
			this.username = this.password = null;
			this.$router.push("/highlights");
		},

		resetCode() {
			this.$store.commit("reset");
			this.$router.push("code");
		}
	},

	i18n: {
		"de": {
			"StateLogin.changecode": "Institution wechseln",
			"StateLogin.disclaimer": "Bei der Eingabe des Systems erkennt der Benutzer das Wissen und die Zustimmung zur Politik der Verarbeitung personenbezogener Daten an",
			"StateLogin.google": "melden Sie sich mit Google an",
			"StateLogin.office365": "melden Sie sich mit Office 365 an",
			"StateLogin.loggingin": "einloggen",
			"StateLogin.login": "einloggen",
			"StateLogin.password": "Passwort",
			"StateLogin.username": "Benutzername",
			"StateLogin.apple": "melden Sie sich bei Apple an",
		},

		"en": {
			"StateLogin.changecode": "Change Institution",
			"StateLogin.disclaimer": "Al entrar al sistema el usuario reconoce conocer y estar de acuerdo con la política de tratamiento de datos personales",
			"StateLogin.google": "Log in with Google",
			"StateLogin.office365": "Log in with Office365",
			"StateLogin.loggingin": "Logging in",
			"StateLogin.login": "Log In",
			"StateLogin.password": "password",
			"StateLogin.username": "username",
			"StateLogin.apple": "Sign in with Apple",
		},

		"es": {
			"StateLogin.changecode": "cambiar institución",
			"StateLogin.disclaimer": "Al entrar al sistema el usuario reconoce y acepta la política de tratamiento de datos personales especificada",
			"StateLogin.google": "Ingresar con Google",
			"StateLogin.office365": "Ingresar con Office365",
			"StateLogin.loggingin": "Ingresando",
			"StateLogin.login": "Ingresar",
			"StateLogin.password": "contraseña",
			"StateLogin.username": "usuario",
			"StateLogin.apple": "Ingresar con Apple",
		},

		"fr": {
			"StateLogin.disclaimer": "Lorsqu'il accède à ce système, l'utilisateur reconnaît avoir connaissance et approuve la politique de traitement des données personnelles",
			"StateLogin.username": "nom d'utilisateur",
			"StateLogin.password": "mot de passe",
			"StateLogin.loggingin": "Se connecter",
			"StateLogin.login": "Se connecter",
			"StateLogin.google": "Connectez-vous avec Google",
			"StateLogin.office365": "Connectez-vous avec Office 365",
			"StateLogin.changecode": "changement d'établissement",
			"StateLogin.apple": "Connectez-vous avec Apple",
		},

		"it": {
			"StateLogin.changecode": "cambia istituto",
			"StateLogin.disclaimer": "All'ingresso nel sistema, l'utente riconosce e accetta la politica di trattamento dei dati personali specificata",
			"StateLogin.google": "Accedi con Google",
			"StateLogin.office365": "Accedi con Office365",
			"StateLogin.loggingin": "Immissione",
			"StateLogin.login": "Login",
			"StateLogin.password": "password",
			"StateLogin.username": "utente",
			"StateLogin.apple": "Accedi con Apple",
		}
	}

	/*
	Autofocus is REALLY anoying on mobile because it opens the keyboard unexpectedly
	mounted() {
		this.$el.querySelector("input").focus();
	}
	*/
}

</script>


<style lang="scss">
#state-login {

	padding: 6px;
	padding-top: 4%;
	text-align: center;

	.phi-card {
		margin: auto;
		width: 100%;
		max-width: 600px;
		padding: 12px;

		img {
			max-width: 100%;
			margin: 24px auto;
			background-color: var(--phi-color-main);
		}
	}

	.phi-input {
		display: block;
		margin-bottom: 32px;
	}

	.phi-button {
		display: block;
		width: 100%;
		margin-bottom: 16px;

		&.google {
			background-color: #eee;
			color: #333;
		}

		&.office365 {
			background-color: #eee;
			color: #333;
		}

		&.apple{
			background-color: #eee;
			color: #333;
		}
	}

	.reset {
		display: block;
		margin: auto;
		width: 100%;
		max-width: 600px;

		margin-top: 15px;

		padding: 12px;
		color: #555;
		text-transform: uppercase;
		text-decoration: none;

		border: 0;
		background: transparent;
		font-size: 1em;
		cursor: pointer;
	}

    .disclaimer {
        padding: 18px;
		font-size: 12px;
        font-weight: 300;
        text-align: center;
        color: #777;
		a {
			color: #5091e0;
			text-decoration: underline;
		}
	}

	& > form {
		position: relative;
	}

	.language-picker {
		position: absolute;
		top: 2px;
		right: 4px;

		select {
			border: 0;
			margin: 0;
			padding: 0;
			background: transparent;
		}
	}
}
</style>